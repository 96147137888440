import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { rgba } from "polished";
import { Helmet } from "react-helmet";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box } from "../components/Core";
import { device } from "../utils";

import CaseList from "../sections/landing1/Testimonial";
import CTA from "../sections/case-study/CTA";
// @ts-expect-error false positive image import failure
import imgBrand from "../assets/image/case-studies/west-suffolk-logo.png";
// @ts-expect-error false positive image import failure
import bgImage from "../assets/image/case-studies/west-suffolk.jpg";
// @ts-expect-error false positive image import failure
import imgCase2 from "../assets/image/jpeg/case-details-half-single.jpg";
// @ts-expect-error false positive image import failure
import imgCase3 from "../assets/image/jpeg/case-details-half-single-2.jpg";

const Quotes = styled.span`
  font-size: 3rem;
  font-weight: 700;
  margin: 0 10px;
  color: #f04037;
`;

const Header = styled(Title)`
  font-size: 4.5rem;
`;
const Quote = styled(Text)`
  font-size: 1.5rem;
`;
const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
`;

const ULStyled = styled.ul`
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding-left: 0;
  margin-bottom: 10px;

  @media ${device.sm} {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 25px;
      height: 25px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 0.8rem;
      margin-right: 0.2rem;
    }
  }
`;

const CaseStudyDetails = () => {
  return (
    <>
      <PageWrapper>
        <Helmet>
          <title>West Suffolk ShopWatch Case Study</title>
          <meta
            name="description"
            content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK."
          />
        </Helmet>
        <Section
          backgroundImage={`url(${bgImage})`}
          backgroundPosition="center"
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          position="relative"
        >
          <Overlay />
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <Box className="text-center" mb={4}>
                  <img src={imgBrand} alt="" className="img-fluid" />
                </Box>
                <Header variant="hero">West Suffolk Council</Header>
                <Quote>
                  <Quotes>"</Quotes>Building a new CCTV Control room demanded
                  significant investment and a solid business case to proceed.
                  <Quotes>"</Quotes>
                </Quote>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="position-relative">
          <Container>
            <Row>
              <Col lg="12" xl="10" className="offset-xl-1">
                <Box pb={["40px", null, "65px"]}>
                  <Title variant="card" mb="18px">
                    Overview
                  </Title>
                  <Text variant="small">
                    Like many councils, St Edmundsbury had a dilemma. Whether to
                    build and staff a new control room or look for another
                    Council or third party to assume responsibility. They
                    decided there were significant benefits to providing the
                    service in-house, but they needed to streamline the systems
                    they supported and generate an income stream to offset their
                    monitoring costs.
                  </Text>
                </Box>
                <Box>
                  <Title variant="card" mb="18px">
                    About the Client
                  </Title>
                  <Text variant="small">
                    St Edmundsbury Borough Council and Forest Heath District
                    Council, have worked together for the last eight years,
                    delivering significant savings and improvements to services.
                    With a joint population of 175,000 and a revenue budget of
                    £100M they are considering a proposal to form a single
                    Council for West Suffolk to deliver the greatest benefits
                    for local businesses and communities including value for
                    money, cost savings, simplicity, self sufficiency and
                    resilience.
                  </Text>
                </Box>
              </Col>
            </Row>
            {/* <Row>
              <Col lg="6" className="mb-lg-0 mb-30">
                <Box>
                  <img src={imgCase2} alt="" className="img-fluid" />
                </Box>
              </Col>
              <Col lg="6">
                <Box>
                  <img src={imgCase3} alt="" className="img-fluid" />
                </Box>
              </Col>
            </Row> */}
          </Container>
        </Section>
        <Section bg="#f6f6f8" py={0}>
          <Container>
            <Row className="pt-5">
              <Col lg="12" xl="10" className="offset-xl-1 pt-4">
                <Box pb={["40px", null, "65px"]}>
                  <Title variant="card" mb="18px">
                    The Challenges
                  </Title>
                  <ULStyled>
                    <li>Big Point 1</li>
                    <li>Big Point 2</li>
                    <li>Big Point 3</li>
                  </ULStyled>
                  <Text variant="small" mb={3}>
                    One of the earliest integrations was an amalgamate of their
                    CCTV control rooms into a single centre. With disparate CCTV
                    and town link radio systems from different manufacturers
                    this provided a significant challenge. The solution however
                    provided significant savings but was a challenge
                    operationally and looking forward it was impossible to plan
                    a migration path to new technology.
                    <br />
                    <br />
                    Whilst ShopSafe migrated the existing analogue radio system
                    to digital in Bury St Edmunds and Stowmarket, Newmarket was
                    provided by another company. ShopSafe was asked to provide a
                    common control system, but the different technologies
                    limited the functionality, and made the system somewhat
                    inflexible with different operating procedures and training.
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section py={1}>
          <Container>
            <Row className="mt-5 mb-5 pb-5">
              <Col lg="12" xl="10" className="offset-xl-1 pt-4">
                <Box>
                  <Title variant="card" mb="18px">
                    The Solution
                  </Title>
                  <Text variant="small">
                    Having carefully reviewed the benefits, St Edmundsbury
                    decided to build a new CCTV control room and to completely
                    overhaul the camera and recording systems to the latest
                    specifications.
                    <br />
                    <br />
                    A key consideration in the business plan was a source of
                    income generation from monitoring, and a common supplier was
                    sought for the radio component.
                    <br />
                    <br />
                    ShopSafe were selected to deliver a common digital radio
                    network across all the towns. Delivered over the same IP
                    network as the CCTV cameras, both CCTV operators and their
                    manager have access to all the towns where cameras are
                    monitored. ShopSafe delivered a common DMR digital network
                    using a range of subscriber products from Hytera. The
                    responsibility to migrate and support the schemes was solely
                    ShopSafe’s. This includes billing to deliver the goal of
                    income generation to offset the running costs for the
                    Council.
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section bg="#f6f6f8" py={0}>
          <Container>
            <Row className="pt-5">
              <Col lg="12" xl="10" className="offset-xl-1 pt-4">
                <Box pb={["40px", null, "65px"]}>
                  <Title variant="card" mb="18px">
                    The Results
                  </Title>
                  <ULStyled>
                    <li>Big Point 1</li>
                    <li>Big Point 2</li>
                    <li>Big Point 3</li>
                  </ULStyled>
                  <Text variant="small">
                    ShopSafe delivers a fully integrated radio network across
                    all the major towns – Bury St Edmunds, Newmarket, Haverhill,
                    Stowmarket and Mildenhall with further expansion planned.
                    Working to an agreed Service Level Commitment, common
                    standards are delivered for everything from training to
                    signage.
                    <br />
                    <br />
                    Within the control room both CCTV operators have access to
                    an extensive suite of software monitoring any combination of
                    towns. All calls are recorded, operators can send texts,
                    stun and revive radios, monitor alarms and can automatically
                    monitor lone workers.
                    <br />
                    <br />
                    ShopSafe deliver comprehensive support including Service
                    Exchange and Batteries for Life and training using both
                    traditional and digital media. With 20 years experience,
                    ShopSafe know every high street head office and procurement
                    route to ensure payment for the radio and the Councils
                    component for monitoring to secure their income generation
                    is guaranteed.
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
        <CaseList />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default CaseStudyDetails;
